<template>
  <h3>Dashboards</h3>
</template>

<script>

export default {
  name: "Dashboards",
};
</script>
